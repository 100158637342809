
import React from 'react';
const importAll = req => req.keys().map(path => ({ name: path.substring(path.lastIndexOf('/') + 1).replace('.svg', ''), path, file: req(path) }));
// const sharedIcons = importAll(reqContext('./shared'));
const sharedIcons = [];


// require.context(), only accept first param as string, so we need to method for each domain.
export const getPkaIcons = () => {
	const pkaIcons = importAll(require.context('./pka', true, /\.svg$/));
	return [...sharedIcons, ...pkaIcons];
};

// export const getPbuIcons = () => {
// 	const pbuIcons = importAll(reqContext('./pbu?url', true));

// 	return [...sharedIcons, ...pbuIcons];
// };

// export const getLpIcons = () => {
// 	const lpIcons = importAll(reqContext('./lp?url', true));

// 	return [...sharedIcons, ...lpIcons];
// };

// export const getSharedIcons = () => {
// 	return [...sharedIcons];
// };

export const getIcon = (name) => {
	const icons = getIcons();
	return icons.find((icon) => icon.name == name);
};

export const getIcons = () => {
	const hostname = getDomain();
	let icons;

	if (hostname === 'pka') {
		icons = importAll(require.context('./pka', true, /\.svg$/));
	} else if (hostname === 'lp') {
		icons = importAll(require.context('./lp', true, /\.svg$/));
	} else if (hostname === 'pbu') {
		icons = importAll(require.context('./pbu', true, /\.svg$/));
	} else {
		icons = [];
	}

	return icons;
};

const getDomain = () => {
	const { hostname } = location;

	if (hostname.includes('pka')) {
		return 'pka';
	} else if (hostname.includes('lppension')) {
		return 'lp';
	} else if (hostname.includes('pbu')) {
		return 'pbu';
	} else {
		return;
	}
};

export const SVGIcon = (icon) => {

	if (!icon) {
		return null;
	}


	const svgicon = getIcon(icon.name);

	if (!svgicon) {
		return null;
	}

	return (<img src={ svgicon.file } alt={ svgicon.name } />);
};