import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import { PrognoseUdbetalingsforloeb } from '../../UdbetalingsForloeb.definition';
import PengeSaek from '../PengeSaek';

const { Chart } = window;

import { IUdbetalingsForloebGrafState } from './UdbetalingsForloebGraf.definition';

export class UdbetalingsForloebGraf extends Component<PrognoseUdbetalingsforloeb, IUdbetalingsForloebGrafState> {
	chartCanvas: HTMLCanvasElement = null;

	constructor(props: PrognoseUdbetalingsforloeb) {
		super(props);
		this.state = {
			hasSum: false,
			isHovering: false,
			kunde: this.props.Kunde
		};

		this.initChart = this.initChart.bind(this);
	}

	async componentDidMount() {
		this.initChart();

	}

	initChart() {
		const chartCanvas = ReactDOM.findDOMNode(this.chartCanvas) as HTMLCanvasElement;
		const self = this;

		let aldre: number[] = [];
		const datasets = [];
		let maxyValue = 0;
		let highestAlder = 0;
		let hasSum = false;
		let fixedTopBulletHeight = 10000;
		let radiusArrMax: number[] = [];
		let sumBeloeb = 0;
		let loebendeBeloeb = 0;
		let radiusArrHalve: number[] = [];
		let skaeveAldreFarve = '#000000';

		// CREATE DATASETS

		this.setState(
			{ hasSum: false }
		);
		hasSum = false;

		let prognoseIndex = 2;
		for (const prognose of this.props.PrognoserPrMedlemskab) {

			//Set hasSum
			if (!prognose.LoebendePrognose) {

				this.setState(
					{ hasSum: true }
				);
				hasSum = true;
			}


			//Set ydelse array
			const ydelseArr = prognose.Prognoser.filter(function (f) {
				if (f.UdloebSkaevAlder) {
					return false; // skip
				}

				return true;
			}).map((item) => {
				if (item.VisPaaGraf && !item.UdloebSkaevAlder) {
					return item.Ydelse;
				} else {
					if (!item.UdloebSkaevAlder) {
						return item.Ydelse;
					} else {
						return null;
					}
				}

			});

			//Set radiusArr array
			let radiusArr: any = prognose.Prognoser.filter(function (f) {
				if (f.UdloebSkaevAlder) {
					return false; // skip
				}

				return true;
			}).map((item) => {
				if (item.VisPaaGraf && !item.UdloebSkaevAlder) {
					return 8;
				} else {
					if (!item.UdloebSkaevAlder) {
						return 0;
					}
				}
			});

			// Add ydelsesarr to Black dots series
			if (prognose.Prognoser.length) {
				if (radiusArrMax.length <= prognose.Prognoser.length) {
					radiusArrMax = radiusArr;
				}
			}

			// Sæt alle points til 0 igen, da de kun skal vises i toppen af grafen
			radiusArr = prognose.Prognoser.filter(function (f) {
				if (f.UdloebSkaevAlder) {
					return false; // skip
				}

				return true;
			}).map((item) => {
				if (item.VisPaaGraf && !item.UdloebSkaevAlder) {
					return 0;
				} else {
					if (!item.UdloebSkaevAlder) {
						return 0;
					}
				}
			});

			radiusArrHalve = radiusArr;
			radiusArrHalve.unshift(null);

			if (prognose.LoebendePrognose) {
				datasets.push({
					label: prognose.Prognoser[0].Label,
					fill: true,
					backgroundColor: prognose.Prognoser[0].FarvePaaGraf,
					pointBackgroundColor: '#fff',
					pointHoverRadius: radiusArr,
					pointRadius: radiusArr,
					pointBorderWidth: 1,
					pointBorderColor: '#000',
					borderColor: 'rgba(220,220,220,0)',
					borderWidth: 0,
					data: ydelseArr,
					type: prognose.LoebendePrognose ? 'line' : 'bar',
					yAxisID: 'yA',
					barPercentage: prognose.LoebendePrognose ? 0 : 1.5,
					order: prognoseIndex
				});
			}

			// Find hojeste alder
			if (highestAlder <= radiusArr.length + 1) {
				highestAlder = radiusArr.length + 1;
			}

			// Hent aldre fra prognoseData
			if (radiusArr.length + 1 === highestAlder) {

				if (prognose.LoebendePrognose) {
					aldre = [];
					prognose.Prognoser.filter(function (f) {
						if (f.UdloebSkaevAlder) {
							return false; // skip
						}

						return true;
					}).map(function (alder) {
						if (alder.VisPaaGraf) {
							aldre.push(alder.AlderVistIGraf);
						} else {
							aldre.push(null); //null
						}
					});
				}
			}

			//Max Y Value
			const tempArr = prognose.Prognoser.map(item => {
				return item.Ydelse;
			});

			if (tempArr.length === 1) {
				sumBeloeb = 0;
			} else {
				const highestYdelse = Math.max(...tempArr);

				loebendeBeloeb = highestYdelse + loebendeBeloeb;
			}

			if (sumBeloeb >= loebendeBeloeb) {
				maxyValue = sumBeloeb;
			} else {
				maxyValue = loebendeBeloeb;
			}

			prognoseIndex++;
		}

		fixedTopBulletHeight = maxyValue;

		const getFixedBulletsData = function () {
			const data = [];
			// eslint-disable-next-line
			for (const item of radiusArrMax) {
				data.push(fixedTopBulletHeight);
			}

			return data;
		};

		const getSkaevtAar = () => {
			let retVar: number = null;

			for (let i = 0; i < self.props.PrognoserPrMedlemskab.length; i++) {
				if (self.props.PrognoserPrMedlemskab[i].LoebendePrognose) {

					for (let y = 0; y < self.props.PrognoserPrMedlemskab[i].Prognoser.length; y++) {
						if (self.props.PrognoserPrMedlemskab[i].Prognoser[y].UdloebSkaevAlder) {
							retVar = y;
						}
					}
				}
			}

			return retVar;
		};

		const getSkaeveAldreData = () => {
			const retVar: number[] = [];

			// Find ud af hvilket år der er en Skaev alder fordi vi skal bruge den til at få ydelsen på evt livsvarige
			const skaevtAar = getSkaevtAar();

			// Loop alle Prognose typer
			for (let i = 0; i < self.props.PrognoserPrMedlemskab.length; i++) {

				// Kør videre hvis det er en løbende prognose og vi sorterer evt SUM væk
				if (self.props.PrognoserPrMedlemskab[i].LoebendePrognose) {

					// Loop Alle Prognoser i den løbende prognose (Livsvarige og rater)
					for (let y = 1; y < self.props.PrognoserPrMedlemskab[i].Prognoser.length; y++) {

						const aar = y;

						if (self.props.PrognoserPrMedlemskab[i].Prognoser[aar].UdloebSkaevAlder) {

							skaeveAldreFarve = self.props.PrognoserPrMedlemskab[i].Prognoser[0].FarvePaaGraf;

							if (retVar[skaevtAar] === null || retVar[skaevtAar] > 0) {

								const ydelseToPush = retVar[skaevtAar] > 0 ? retVar[skaevtAar - 1] + self.props.PrognoserPrMedlemskab[i].Prognoser[skaevtAar - 1].Ydelse : self.props.PrognoserPrMedlemskab[i].Prognoser[skaevtAar - 1].Ydelse;
								retVar[skaevtAar] = ydelseToPush;

								const ydelseToPushMinusOne = retVar[skaevtAar - 1] > 0 ? retVar[skaevtAar - 1] + self.props.PrognoserPrMedlemskab[i].Prognoser[skaevtAar - 1].Ydelse : self.props.PrognoserPrMedlemskab[i].Prognoser[skaevtAar - 1].Ydelse;
								retVar[skaevtAar - 1] = ydelseToPushMinusOne;


							} else {
								retVar.push(self.props.PrognoserPrMedlemskab[i].Prognoser[skaevtAar - 1].Ydelse);
								retVar.push(self.props.PrognoserPrMedlemskab[i].Prognoser[skaevtAar - 1].Ydelse);
							}

						} else {
							if (retVar[skaevtAar] !== null) {
								retVar.push(null);
							}
						}

					}

					// Tilføj evt livsvarige til den halve graf så den kommer på niveau!
					if (skaevtAar != null && self.props.PrognoserPrMedlemskab[i].Prognoser.length >= skaevtAar + 1) {
						const harSkaevAlder: boolean = self.props.PrognoserPrMedlemskab[i].Prognoser[skaevtAar].UdloebSkaevAlder ? self.props.PrognoserPrMedlemskab[i].Prognoser[skaevtAar].UdloebSkaevAlder : false;

						if (!harSkaevAlder) {
							retVar[skaevtAar] = retVar[skaevtAar] + self.props.PrognoserPrMedlemskab[i].Prognoser[skaevtAar].Ydelse; // + ((self.props.PrognoserPrMedlemskab[i].Prognoser[skaevtAar].Ydelse*3)/100);
							retVar[skaevtAar - 1] = retVar[skaevtAar - 1] + self.props.PrognoserPrMedlemskab[i].Prognoser[skaevtAar - 1].Ydelse; // + ((self.props.PrognoserPrMedlemskab[i].Prognoser[skaevtAar-1].Ydelse * 3) / 100);
						}
					}

				}

			}

			return retVar;
		};

		// Add additional dfataset for top line
		datasets.push({
			label: 'hidden',
			type: 'LineWithLine',
			fill: false,
			pointBackgroundColor: '#003e53',
			pointHoverRadius: radiusArrMax,
			pointRadius: radiusArrMax,
			pointBorderWidth: 1,
			pointBorderColor: '#003e53',
			borderColor: '#ffffff',
			borderWidth: 0,
			data: getFixedBulletsData(),
			yAxisID: 'yB',
			xAxisID: 'xB',
			order: 10
		});

		// Tilføj ekstra dataset for at vise Skæv alder
		datasets.push({
			label: 'hiddenHalve',
			type: 'line',
			fill: true,
			data: getSkaeveAldreData(), //ydelseArrTest,
			backgroundColor: skaeveAldreFarve,
			pointBackgroundColor: '#83981F',
			pointHoverRadius: radiusArrHalve,
			pointRadius: radiusArrHalve,
			pointBorderWidth: 0,
			pointBorderColor: '#003e53',
			borderColor: '#83981F',
			borderWidth: 0,
			showLine: true,
			yAxisID: 'yC',
			xAxisID: 'xC',
			order: 20

		});


		Chart.defaults.LineWithLine = Chart.defaults.line;
		const originalDraw = Chart.controllers.line.prototype.draw;
		Chart.controllers.LineWithLine = Chart.controllers.line.extend({

			draw: function (ease: any) {
				if (originalDraw) {
					originalDraw.call(this, ease);
				}
				const { chart } = this;
				const { ctx } = chart.chart;

				const hiddenDatasetId = chart.config.data.datasets.length - 2;

				const infoicon = new Image();
				//infoicon.src = 'https://i.imgur.com/yDYW1I7.png';
				infoicon.src = 'https://' + window.location.host + '/static/img/icons/dot_small.png';

				//qvar chart = controller.chart;
				const xOffset = chart.width - (chart.width - 50);
				const yOffset = chart.height - (chart.height - 18);
				ctx.fillText(self.props.Felter.tekstYAkse, xOffset, yOffset);

				// Tilføj X akse titel til højre
				const xOffset2 = chart.width - 25;
				const yOffset2 = chart.height - 90;
				ctx.fillText(self.props.Felter.tekstXAkse == null ? '' : self.props.Felter.tekstXAkse, xOffset2, yOffset2);


				const startIndex = hasSum ? 0 : 0;
				for (let i = startIndex; i < chart.config.data.datasets[hiddenDatasetId].data.length; i++) {


					const strokeColor = '#003e53';

					if (chart.config.data.datasets[hiddenDatasetId].pointRadius[i] > 0) {

						let meta = 0;
						for (let y = 0; y <= 100; y++) {
							if (chart.config.data.datasets[hiddenDatasetId]._meta[y]) {
								meta = y;
							}

						}


						chart.config.data.datasets[hiddenDatasetId]._meta[meta].data[i]._model.pointStyle = infoicon;


						const index = i;

						if (index) {
							const xaxis = chart.scales['xA'];
							const yaxis = chart.scales['yA'];

							const meta = chart.getDatasetMeta(hiddenDatasetId);
							const topY = meta.data[i]._model.y;

							ctx.save();
							ctx.beginPath();
							ctx.moveTo(xaxis.getPixelForValue(undefined, index), topY);
							ctx.strokeStyle = strokeColor;
							ctx.lineTo(xaxis.getPixelForValue(undefined, index), yaxis.bottom + 5);
							ctx.stroke();
							ctx.restore();
						}

					}
				}

				Chart.controllers.line.prototype.draw.call(this, ease);

				if (this.chart.tooltip._active && this.chart.tooltip._active.length) {

					//var sun = new Image();
					//sun.src = 'https://i.imgur.com/yDYW1I7.png';
					//chart.config.data.datasets[hiddenDatasetId]._meta[0].data[i]._model.pointStyle = sun;

					if (chart.config.data.datasets[hiddenDatasetId].pointRadius[0] > 0) {

						// Tjek om dette datapunkt er en skael alder
						let leftXOffset = 0;


						const activePoint = this.chart.tooltip._active[this.chart.tooltip._active.length - 1];
						const { ctx } = this.chart;
						const { x } = activePoint.tooltipPosition();
						const topY = 64; //activePoint.tooltipPosition().y,
						const bottomY = this.chart.scales['yA'].bottom;

						const skaevtaar = getSkaevtAar();

						if (activePoint._index === (skaevtaar - 1) || activePoint._index === skaevtaar) {
							leftXOffset = 25;
						}

						// draw line
						ctx.save();
						ctx.beginPath();
						ctx.moveTo(x + leftXOffset, topY + 5);
						ctx.lineTo(x + leftXOffset, bottomY);
						ctx.lineWidth = 2;
						ctx.strokeStyle = '#00557a';
						ctx.stroke();
						ctx.restore();
					}
				}
			}
		});

		/// datapoints er data fra chart
		/// rowIndex er index på hver række som skal vises i popup
		const getFormattedNumber = (datapoints: any) => {
			let retVar: string = null;

			for (const item of self.props.PrognoserPrMedlemskab) {
				item.Prognoser.map(function (d: any) {
					if (d.Ydelse == datapoints.yLabel) {
						retVar = d.YdelseFormateret;
						return retVar;
					}
				});
			}

			return retVar;
		};

		const getTotalYdelse = (datapoints: any) => {
			let retVar: number = null;

			for (const item of self.props.PrognoserPrMedlemskab) {
				item.Prognoser.map(function (d: any) {
					if (d.Ydelse == datapoints.yLabel) {
						retVar = parseInt(d.YdelseFormateret.replace('.', ''));
						return retVar;
					}
				});
			}

			return retVar;
		};

		const getKroner = (datapoints: any) => {
			let retVar: string = null;

			for (const item of self.props.PrognoserPrMedlemskab) {
				item.Prognoser.map(function (d: any) {
					if (d.Ydelse == datapoints.yLabel) {
						if (!item.LoebendePrognose) {
							retVar = 'kr.';
							return retVar;
						} else {
							if (d.VisUdloebsTekstMindreEndEtAar) {
								retVar = 'kr./md.';
								return retVar;
							} else {
								retVar = 'kr./år';
								return retVar;
							}
						}
					}
				});
			}

			return retVar;
		};

		const tekstVedSkævtUdløb = (datapoints: any) => {
			let retVar = '';

			for (const item of self.props.PrognoserPrMedlemskab) {
				item.Prognoser.map(function (d: any) {
					if (d.Ydelse == datapoints.yLabel && d.VisUdloebsTekst) {
						retVar = '<br/><span style="font-size: 0.82em; line-height: 1.0em; color:grey">' + self.props.Felter.tekstVedUdloeb + d.UdloebsdatoFormateret + '</span>';
					}
				});
			}

			return retVar;
		};

		const findFormateretStartDato = (datapoints: any) => {
			let retVar = '';

			for (const item of self.props.PrognoserPrMedlemskab) {
				for (const prognose of item.Prognoser) {
					if (prognose.Ydelse == datapoints.yLabel) {
						retVar = prognose.DatoStartFormateret;
						return prognose.DatoStartFormateret;
					}
				}
			}

			return retVar;
		};


		//Chart.defaults.global.showLines

		// Customtooltip
		const customTooltips = function (tooltipModel: any) {
			// Tooltip Element
			let tooltipEl = document.getElementById('chartjs-tooltip');

			// Create element on first render
			if (!tooltipEl) {
				tooltipEl = document.createElement('div');
				tooltipEl.id = 'chartjs-tooltip';

				tooltipEl.innerHTML = '<div id="tooltip-title"></div><table id="tooltip-table"></table>';
				document.body.appendChild(tooltipEl);
			}

			// Hide if no tooltip
			if (tooltipModel.opacity === 0) {
				tooltipEl.style.opacity = '0';
				return;
			}

			// Set caret Position
			tooltipEl.classList.remove('above', 'below', 'no-transform');

			if (tooltipModel.yAlign) {
				tooltipEl.classList.add(tooltipModel.yAlign);
			} else {
				tooltipEl.classList.add('no-transform');
			}

			function getBody(bodyItem: any) {
				return bodyItem.lines;
			}


			// Set Text
			if (tooltipModel.body) {
				const titleLines = tooltipModel.title || [];
				const bodyLines = tooltipModel.body.map(getBody);

				let titleHtml = '';
				let innerHtml = '<thead>';

				titleLines.forEach(function (title: any) {

					switch (title) {
					case '0':
						titleHtml += '<div class="title">' + self.props.Felter.overskriftPopupFoersteAar.replace('[folkepensionsalder]', self.props.PrognoserPrMedlemskab[0].Prognoser[0].PensionsAlderFormateret).replace('[dato]', self.props.PrognoserPrMedlemskab[0].Prognoser[0].DatoStartFormateret) + '</div>';
						break;
					case '1':
						titleHtml += '<div class="title">' + self.props.Felter.overskriftPopupFoersteAar.replace('[folkepensionsalder]', self.props.PrognoserPrMedlemskab[0].Prognoser[0].PensionsAlderFormateret).replace('[dato]', findFormateretStartDato(tooltipModel.dataPoints[0])) + '</div>';
						break;
					case '99':
						titleHtml += '<div class="title">' + self.props.Felter.tekstVedUdloeb + self.props.PrognoserPrMedlemskab[0].Prognoser[0].UdloebsdatoFormateret + '</div>';
						break;
					default:
						titleHtml += '<div class="title">' + self.props.Felter.overskriftPopupAndreAar.replace('[år]', title).replace('[år-1]', (title - 1).toString()).replace('[dato]', self.props.PrognoserPrMedlemskab[0].Prognoser[0].DatoStartFormateret) + '</div>';
						break;
					}
				});
				innerHtml += '</thead><tbody>';
				let sumTotal = 0;
				bodyLines.forEach(function (body: string, i: number) {

					const colors = tooltipModel.labelColors[i];
					let style = 'background-color:' + colors.backgroundColor;
					style += '; border-color:' + colors.borderColor;
					style += '; border-width: 2px';
					const span = '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
					const parts = body[0].split(':');

					if (parts[0] !== 'hidden' && parts[0] !== 'hiddenHalve') {
						sumTotal = sumTotal + getTotalYdelse(tooltipModel.dataPoints[i]);
						innerHtml += '<tr class="text-right nowrap"><td>' + span + parts[0] + tekstVedSkævtUdløb(tooltipModel.dataPoints[i]) + '</td><td class="text-right nowrap">' + span + getFormattedNumber(tooltipModel.dataPoints[i]) + '</td><td>' + getKroner(tooltipModel.dataPoints[i]) + '</td></tr>';
					}
				});

				bodyLines.forEach(function (body: string, i: number) {
					if (body[0].indexOf('hidden') > -1 || body[0].indexOf('hiddenHalve') > -1) {
						bodyLines.splice(i, 2);
					}
				});


				if (bodyLines.length > 1 ) {
					innerHtml += '<tr><td> I alt' + '</td><td class="text-right nowrap">' + sumTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '</td><td>kr./år</td></tr>';
				}
				innerHtml += '</tbody>';

				const titleContainer = tooltipEl.querySelector('div#tooltip-title');
				//titleContainer.innerHTML = titleHtml;

				const tableRoot = tooltipEl.querySelector('table');
				tableRoot.innerHTML = innerHtml;

				titleContainer.innerHTML = titleHtml + tableRoot;
			}

			// `this` will be the overall tooltip
			const position = this._chart.canvas.getBoundingClientRect();

			/// Display, position, and set styles for font
			tooltipEl.style.opacity = '1';
			tooltipEl.style.position = 'absolute';
			tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
			tooltipEl.style.top = position.top + window.pageYOffset - document.querySelector('#chartjs-tooltip').clientHeight + 30 + 'px';
			tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
			tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
			tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
			tooltipEl.style.pointerEvents = 'none';

		};

		// Dataset sorteres
		const sortedDatasets = datasets.sort((a, b) => (a.order > b.order) ? 1 : -1);

		const mixedChart = new Chart(chartCanvas, {
			type: 'line',
			data: {
				labels: aldre,
				datasets: sortedDatasets

			},
			options: {
				//events: ['click'],
				maintainAspectRatio: false,
				responsive: false,
				layout: {
					padding: {
						left: 0,
						right: 30,
						top: 0,
						bottom: 0
					}
				},

				title: {
					display: true,
					text: ''
				},
				tooltips: {

					intersect: true,
					enabled: false,
					mode: 'index',
					position: 'average',

					custom: customTooltips

				},
				legendCallback: function (chart) {
					const text = [];
					text.push('<div class="small-12 medium-12 medium-centered columns custom-legend-container" >');

					if (hasSum) {
						const color = self.props.PrognoserPrMedlemskab.filter(function (f: any) {
							if (f.LoebendePrognose) {
								return false;
							}

							return true;
						})[0].Prognoser[0].FarvePaaGraf;

						if (!self.props.PrognoserPrMedlemskab.filter(function (f) {
							if (f.LoebendePrognose) {
								return false;
							}

							return true;
						})[0].LoebendePrognose) {
							text.push('<div class="columns small-12 medium-6 custom-legend-item" >');
							text.push('<span class="custom-legend-boxicon" style="background-color:' + color + '"></span><span class="custom-legend-title">' + self.props.PrognoserPrMedlemskab[0].Prognoser[0].Label + '</span>');
							text.push('</div>');
						}
					}

					for (let i = 0; i < chart.data.datasets.length; i++) {
						if (chart.data.datasets[i].label.indexOf('hidden') === -1) {
							text.push('<div class="columns small-12 medium-6 custom-legend-item" >');
							text.push('<span class="custom-legend-boxicon" style="background-color:' + chart.data.datasets[i].backgroundColor + '"></span><span class="custom-legend-title">' + chart.data.datasets[i].label + '</span>');
							text.push('</div>');
						}
					}
					text.push('</div>');

					if (self.props.VisFooterTekst) {
						text.push('<div class=" small-12 medium-12 medium-centered columns custom-legend-footertext">');
						text.push('<span>' + self.props.FooterTekst + '</span>');
						text.push('</div>');
					}


					return text.join('');
				},
				legend: {
					display: false,
					position: 'bottom',
					labels: {
						fontColor: 'rgb(0,0,0)',
						fontSize: 14,
						filter: function () {
							// Logic to remove a particular legend item goes here
							//return !item.text.includes('hidden');
						}

					},
					onClick: (e) => e.stopPropagation()
				},
				elements: {
					line: {
						tension: 0
					}
				},
				animation: {
					duration: 750
				},
				scales: {

					xAxes: [
						{
							// Løbende
							id: 'xA',
							distribution: 'linear',
							gridLines: {
								borderDash: [5, 5],
								color: 'rgba(255,255,255, 0.4)',
								display: true,
								z: 1
							},
							scaleLabel: {
								display: true,
								labelString: ''

							},
							afterFit: function (scaleInstance) {
								scaleInstance.height = 60;
							},
							ticks: {
								callback: function (tickValue: any) {
									if (tickValue != null && tickValue != -1) {
										if (tickValue == 1) {
											return self.props.Felter.tekstXAksePunkt0.replace('[folkepensionsalder]', self.props.PrognoserPrMedlemskab[0].Prognoser[0].PensionsAlderFormateret);
										} else
											if (tickValue == 99) {
												return self.props.PrognoserPrMedlemskab[0].Prognoser[0].UdloebsdatoFormateret;
											} else {
												return self.props.Felter.tekstXAksePunktAndreAar.replace('[år]', tickValue).replace('[år-1]', (tickValue - 1).toString());
											}

									} else {
										return ' ';
									}
								}
							}
						},
						{
							// Løbende
							display: false, // Hide xaxis
							id: 'xB',
							distribution: 'linear',
							gridLines: {
								borderDash: [5, 5],
								color: 'rgba(0,0,0, 0.4)',
								display: true,
								z: 1
							},
							scaleLabel: {
								display: false,
								labelString: 'År efter pensionsalder'
							},
							ticks: {
								callback: function (tickValue) {

									//if (showInGraf(tickValue, index)) {
									if (tickValue != null && tickValue != -1) {
										return ''; //' ß';
									} else {
										return ''; //' ';
									}
								}
							}
						},
						{
							// Halve
							display: false, // Hide xaxis
							id: 'xC',
							bounds: 'ticks',
							offset: false,

							gridLines: {
								display: false,
								tickMarkLength: 10,
								z: 2
							},
							scaleLabel: {
								display: false,
								labelString: ''

							},
							ticks: {
								autoSkip: false
							}
						}
					],

					yAxes: [
						{
							id: 'yA',
							stacked: true,
							position: 'left',
							scaleLabel: {
								display: false

							},
							afterFit: function (scaleInstance) {

								if (self.state.kunde === 'LP') {
									scaleInstance.width = 95;
								} else {
									scaleInstance.width = 60;
								}
							},
							ticks: {
								fontFamily: '\'Roboto, Arial,\' sans-serif',
								max: fixedTopBulletHeight + Math.round((fixedTopBulletHeight * 15) / 100),
								min: 0,
								callback: function (value: number) {
									if (self.state.kunde === 'LP') {
										if (hasSum) {
											if (value > maxyValue / 2) {
												return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
											} else {
												return '';
											}
										} else {
											return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
										}
									} else {
										return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
									}


								}
								//padding: 10
							},


							gridLines: {
								display: false,
								tickMarkLength: 10
							},
							afterTickToLabelConversion: function (scaleInstance) {
								// set the first and last tick to null so it does not display
								// note, ticks[0] is the last tick and ticks[length - 1] is the first
								scaleInstance.ticks[0] = null;
								scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;

								// need to do the same thing for this similiar array which is used internally
								scaleInstance.ticksAsNumbers[0] = null;
								scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = null;
							}
						},
						{
							display: false,
							id: 'yB',
							stacked: true,
							position: 'left',
							scaleLabel: {
								display: false
							},
							ticks: {
								max: maxyValue + Math.round((maxyValue * 10) / 100),
								min: 0

							},
							gridLines: {
								display: false,
								tickMarkLength: 10
							},
							afterTickToLabelConversion: function (scaleInstance) {
								// set the first and last tick to null so it does not display
								// note, ticks[0] is the last tick and ticks[length - 1] is the first
								scaleInstance.ticks[0] = null;
								scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;

								// need to do the same thing for this similiar array which is used internally
								scaleInstance.ticksAsNumbers[0] = null;
								scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = null;
							}
						},
						{
							display: false,
							id: 'yC',
							stacked: true,
							position: 'right',
							scaleLabel: {
								display: false

							},
							ticks: {
								max: fixedTopBulletHeight + Math.round((fixedTopBulletHeight * 15) / 100),
								min: 0,
								callback: function (value) {
									return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
								},
								padding: 10
							},


							gridLines: {
								display: false
							}

						}
					]


				}
			},
			plugins: [{
				afterUpdate: function (chart: any) {

					const hiddenHalveDatasetId = chart.config.data.datasets.length - 1;

					const dataset = chart.config.data.datasets[hiddenHalveDatasetId];
					const offset = -25;

					let meta = 0;
					for (let y = 0; y <= 100; y++) {
						if (dataset._meta[y]) {
							meta = y;
						}
					}

					for (let o = 0; o < dataset._meta[meta].data.length; o++) {
						const model = dataset._meta[meta].data[o]._model;
						model.x += offset;
						model.controlPointNextX += offset;
						model.controlPointPreviousX += offset;
					}
				}
			}]


		});


		mixedChart.update();

		// Tilføj custom legend til div
		if (document.getElementById('chart-legends-' + this.props.Index)) {
			document.getElementById('chart-legends-' + this.props.Index).innerHTML = mixedChart.generateLegend().toString();
		}
	}

	private toggleHover = () => {
		this.setState({ isHovering: !this.state.isHovering });
	};

	// Returnerer et array af UdbetalingsforloebPrMedlemskab[] som kun indeholder SUM typen
	sumUdbetalinger = () => {
		const sumUdbetalingListe = this.props.PrognoserPrMedlemskab.filter(function (f) {
			if (f.LoebendePrognose) {
				return false; // skip
			}

			return true;
		}).map(function (prognose) {
			return prognose;
		});

		return sumUdbetalingListe;
	};

	// Returnerer JSX fra Pengesaek
	visPengeSaek() {
		if (this.state.hasSum) {
			if (this.state.kunde === 'LP') {
				return (
					<div>
						<PengeSaek
							prognoser={ this.sumUdbetalinger() }
							positionIsAbsolute={ true }
							kunde={ this.state.kunde }
							label={ this.props.PrognoserPrMedlemskab[0].Prognoser[0].Label }
							ydelse={ this.props.PrognoserPrMedlemskab[0].Prognoser[0].YdelseFormateret }
							folkepensionsAlder={ this.props.Felter.overskriftPopupFoersteAar.replace('[folkepensionsalder]', this.props.PrognoserPrMedlemskab[0].Prognoser[0].PensionsAlderFormateret).replace('[dato]', this.props.PrognoserPrMedlemskab[0].Prognoser[0].DatoStartFormateret) }
							class={ `container${this.state.isHovering ? ' showSumToolTip' : ' hideSumToolTip'}` }
							color={ this.props.PrognoserPrMedlemskab.filter(function (f) {
								if (f.LoebendePrognose) {
									return false;
								}

								return true;
							})[0].Prognoser[0].FarvePaaGraf }
							top={ null }
							left={ 10 }
							bottom={ 51 }
							onEnter={ this.toggleHover }
							onOut={ this.toggleHover } />
					</div>
				);
			} else {
				return (
					<div>
						<PengeSaek
							prognoser={ this.sumUdbetalinger() }
							positionIsAbsolute={ true }
							kunde={ this.state.kunde }
							label={ this.props.PrognoserPrMedlemskab[0].Prognoser[0].Label }
							ydelse={ this.props.PrognoserPrMedlemskab[0].Prognoser[0].YdelseFormateret }
							folkepensionsAlder={ this.props.Felter.overskriftPopupFoersteAar.replace('[folkepensionsalder]', this.props.PrognoserPrMedlemskab[0].Prognoser[0].PensionsAlderFormateret).replace('[dato]', this.props.PrognoserPrMedlemskab[0].Prognoser[0].DatoStartFormateret) }
							class={ `container${this.state.isHovering ? ' showSumToolTip' : ' hideSumToolTip'}` }
							color={ this.props.PrognoserPrMedlemskab.filter(function (f) {
								if (f.LoebendePrognose) {
									return false;
								}

								return true;
							})[0].Prognoser[0].FarvePaaGraf }
							top={ null }
							left={ 59 }
							bottom={ 51 }
							onEnter={ this.toggleHover }
							onOut={ this.toggleHover } />
					</div>
				);
			}
		}
	}

	// Hvis der kun er en SUM og ingen andre ordninger
	// skal visSumBox vises i stedet for grafen
	visSumBox() {
		if (this.state.hasSum && this.props.PrognoserPrMedlemskab.length === 1) {
			return (
				<div className="row base-band sumbox">
					<div className="columns small-12">
						<div className="row ">
							<div className="columns small-1 medium-1 left">
								<PengeSaek prognoser={ this.sumUdbetalinger() } positionIsAbsolute={ false } kunde={ this.state.kunde } label={ this.props.PrognoserPrMedlemskab[0].Prognoser[0].Label } ydelse={ this.props.PrognoserPrMedlemskab[0].Prognoser[0].YdelseFormateret } folkepensionsAlder={ this.props.Felter.overskriftPopupFoersteAar.replace('[folkepensionsalder]', this.props.PrognoserPrMedlemskab[0].Prognoser[0].PensionsAlderFormateret).replace('[dato]', this.props.PrognoserPrMedlemskab[0].Prognoser[0].DatoStartFormateret) } class={ `container${this.state.isHovering ? ' showSumToolTip' : ' hideSumToolTip'}` } color={ this.props.PrognoserPrMedlemskab[0].Prognoser[0].FarvePaaGraf } top={ null } left={ 20 } bottom={ 67 } onEnter={ this.toggleHover } onOut={ this.toggleHover } />
							</div>
							<div className="columns small-11 medium-11 right">
								<div className="sumbox-header">{ this.props.PrognoserPrMedlemskab[0].Prognoser[0].Label }</div>
								<div className="sumbox-text">{ this.props.Felter.tekstTilRenSum.replace('[beløb]', this.props.PrognoserPrMedlemskab[0].Prognoser[0].YdelseFormateret) }
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

	visLegend() {
		if (this.state.hasSum && this.props.PrognoserPrMedlemskab.length === 1) {
			return <div />;
		} else {
			return (
				<div>
					<div id={ 'chart-legends-' + this.props.Index } />
				</div>
			);
		}
	}

	visBoxVedSidenAfGraf() {
		if (this.state.kunde === 'LP' && this.state.hasSum) {
			return 'lp-canvas';
		}
	}

	testJson() {
		return <div className="testJson"><pre>{ JSON.stringify(this.props.PrognoserPrMedlemskab, null, 2) }</pre></div>;
	}

	render() {

		const prognoserTilGraf = (
			<div className="graph-wrapper">
				<canvas
					className={ this.visBoxVedSidenAfGraf() }
					ref={ chart => {
						this.chartCanvas = chart;
					} } />
				{ this.visPengeSaek() }
			</div>
		);


		return (
			<div className="base-band  columns medium-12 large-12">
				{ this.state.hasSum && this.props.PrognoserPrMedlemskab.length === 1 ? this.visSumBox() : prognoserTilGraf }
				{ this.visLegend() }
				{ this.props.Felter.visJson ? this.testJson() : <div /> }
			</div>
		);
	}
}

export default UdbetalingsForloebGraf;